export default {
  badWords: [
    'anal',
    'anus',
    'arse',
    'ass',
    'asshole',
    'assrammer',
    'b00bs',
    'bastard',
    'baise',
    'baiser',
    'baiseur',
    'baisse',
    'balls',
    'ballsack',
    'bander',
    'bastard',
    'bat',
    'batard',
    'batarde',
    'batte',
    'biatch',
    'biffle',
    'biffler',
    'bifle',
    'bifler',
    'bitch',
    'biatch',
    'bitche',
    'bitches',
    'bitchs',
    'bite',
    'bloody',
    'blow job',
    'blowjob',
    'bollock',
    'bollok',
    'boner',
    'boob',
    'boobs',
    'bordel',
    'bordelle',
    'boukak',
    'boukake',
    'boukakke',
    'boukkake',
    'boule',
    'boules',
    'boulle',
    'boulles',
    'branle',
    'branlee',
    'branler',
    'branlet',
    'branlett',
    'branlette',
    'branleur',
    'breast',
    'breastfed',
    'breasts',
    'broute',
    'brouter',
    'broutter',
    'bugger',
    'bum',
    'butt',
    'butthole',
    'buttpirate',
    'buttplug',
    'cabron',
    'caliboir',
    'caliboire',
    'calice',
    'calise',
    'calisse',
    'chatte',
    'chiant',
    'chiante',
    'chiase',
    'chiases',
    'chiasse',
    'chiasser',
    'chiasses',
    'chie',
    'chiee',
    'chien',
    'chienase',
    'chienasse',
    'chiennase',
    'chiennasse',
    'chienne',
    'chier',
    'chieur',
    'chieure',
    'chiote',
    'chiott',
    'chiotte',
    'chiottes',
    'ciboir',
    'ciboire',
    'clit',
    'clitoris',
    'cock',
    'cocks',
    'cockhead',
    'cocksucker',
    'conard',
    'conarde',
    'conase',
    'conasse',
    'connard',
    'connarde',
    'connase',
    'connasse',
    'contrecrisser',
    'coon',
    'couille',
    'couilles',
    'crap',
    'criss',
    'crisse',
    'crissement',
    'crissemment',
    'cul',
    'cunt',
    'damn',
    'debande',
    'debandee',
    'debandee',
    'debander',
    'decalise',
    'decalise',
    'decaliser',
    'decalisse',
    'decalisse',
    'decalisse',
    'decalisser',
    'decalisser',
    'decrise',
    'decriser',
    'decrisez',
    'decrisse',
    'decrisser',
    'decrissez',
    'defonce',
    'defoncer',
    'defonceur',
    'defonceuse',
    'defoncez',
    'demerde',
    'demerder',
    'depucele',
    'depuceler',
    'diare',
    'diaree',
    'diaree',
    'diarhe',
    'diarree',
    'diarrhe',
    'diarrhe',
    'diarrhee',
    'diarrhee',
    'dick',
    'dicks',
    'dildo',
    'doigte',
    'doigter',
    'duccon',
    'ducon',
    'dyke',
    'ecouille',
    'ecouiller',
    'ejaculate',
    'ejaculer',
    'emerde',
    'emerder',
    'emerdeur',
    'emmerde',
    'emmerder',
    'emmerdeur',
    'enculade',
    'enculade',
    'encule',
    'encule',
    'enculement',
    'enculemment',
    'enculer',
    'enculer',
    'enculeri',
    'enculerie',
    'enculeur',
    'enculeuse',
    'enculez',
    'encullade',
    'enculle',
    'enculle',
    'encullement',
    'encullemment',
    'enculler',
    'enculleur',
    'enculleuse',
    'encullez',
    'enfoire',
    'enfoiree',
    'enfoirer',
    'esti',
    'estie',
    'estis',
    'fag',
    'fags',
    'faggit',
    'faggot',
    'fatass',
    'f u c k',
    'f*ck',
    'f*cking',
    'facial',
    'faciale',
    'fag',
    'fags',
    'fdp',
    'feces',
    'feck',
    'felching',
    'fellate',
    'fellatio',
    'fesse',
    'fif',
    'fife',
    'fifes',
    'fiff',
    'fiffe',
    'fiffi',
    'fiffis',
    'fifi',
    'fifis',
    'fifs',
    'filsdepute',
    'fist',
    'fister',
    'flange',
    'foure',
    'fourer',
    'fourre',
    'fourrer',
    'foutre',
    'fuck',
    'fucker',
    'fucking',
    'fudge packer',
    'fudgepacker',
    'fuk',
    'fyf',
    'fyfe',
    'fyffes',
    'fyffs',
    'fyfs',
    'gay',
    'gland',
    'glander',
    'god damn',
    'goddamn',
    'graine',
    'graines',
    'gros',
    'grosse',
    'gueule',
    'hell',
    'hitler',
    'homo',
    'imbaisable',
    'imbaisables',
    'imbecile',
    'imbeciles',
    'jerk',
    'jew',
    'jews',
    'jizz',
    'kamasutra',
    'knob end',
    'knobend',
    'kriss',
    'labia',
    'lesbian',
    'lmao',
    'lmfao',
    'marde',
    'mardes',
    'mardeuse',
    'mardeuses',
    'mardeux',
    'masochiste',
    'masturbate',
    'masturber',
    'meconum',
    'merdase',
    'merdasse',
    'merde',
    'merdes',
    'merdeuse',
    'merdeuses',
    'merdeux',
    'mothafucka',
    'motherfucker',
    'mother fucker',
    'muff',
    'nibba',
    'nigga',
    'niggah',
    'nigger',
    'nike',
    'niker',
    'nique',
    'niquer',
    'orgasm',
    'orgasme',
    'osti',
    'ostie',
    'ostis',
    'pd',
    'pedal',
    'pedale',
    'pedales',
    'pedals',
    'pede',
    'pedes',
    'pee',
    'penis',
    'pet',
    'pete',
    'peter',
    'pets',
    'pette',
    'petter',
    'pine',
    'piner',
    'pipe',
    'pipes',
    'piss',
    'pisse',
    'pisseuse',
    'pisseux',
    'plote',
    'plotes',
    'plotte',
    'plottes',
    'poil',
    'poils',
    'poop',
    'poopie',
    'porn',
    'preteen',
    'prick',
    'ptain',
    'pube',
    'pussy',
    'putain',
    'pute',
    'putes',
    'queef',
    'queer',
    'queers',
    'queue',
    'queues',
    'rectum',
    'retard',
    's hit',
    'salaud',
    'salauds',
    'salaux',
    'sallope',
    'sallopes',
    'salopard',
    'salopards',
    'salope',
    'salopes',
    'scrotum',
    'semen',
    'sex',
    'sexe',
    'sexuele',
    'sexuelle',
    'sexy',
    'shemale',
    'sh1t',
    'shit',
    'shitfuck',
    'shitter',
    'siboir',
    'siboire',
    'skank',
    'slut',
    'slutz',
    'smegma',
    'sonofabitch',
    'spunk',
    'suce',
    'sucer',
    'suceur',
    'suceuse',
    'suck',
    'sucka',
    'sucker',
    'suckadick',
    'suka',
    'tabarnac',
    'tabarnack',
    'tabarnacle',
    'tabarnak',
    'tabarnaque',
    'tabernacle',
    'tapete',
    'tapetes',
    'tapette',
    'tapettes',
    'tappete',
    'tappetes',
    'tappette',
    'tappettes',
    'teets',
    'testicule',
    'testical',
    'tg',
    'tit',
    'tits',
    'tosser',
    'tripote',
    'tripoter',
    'trippote',
    'trippote',
    'trippoter',
    'trou du cul',
    'trouduc',
    'trui',
    'truie',
    'turd',
    'twat',
    'vagin',
    'vagina',
    'vaginal',
    'vagiplotte',
    'verge',
    'vieille',
    'vieilles',
    'vielle',
    'vielles',
    'vieu',
    'vieux',
    'vulva',
    'vulve',
    'wank',
    'wanker',
    'wh0re',
    'whore',
    'wtf',
    'whatthefuck',
    'xrated',
    'xxx',
    'yeul',
    'yeule',
  ],
  happyWords: [
    'allégresse',
    'amour',
    'bonheur',
    'crème glacée',
    'framboise',
    'gentillesse',
    'joie',
    'melon d\'eau',
    'paix',
    'radieux',
    'respect',
    'soleil',
    'tournesol',
  ],
};
